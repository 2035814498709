<template>
  <div>
    <v-card>
      <v-toolbar flat>
        <v-toolbar-title>Exames</v-toolbar-title>

        <v-spacer></v-spacer>
        <v-btn small rounded color="success" @click="openInsertScreen">
          Cadastrar <v-icon right dark> mdi-plus </v-icon></v-btn
        >
      </v-toolbar>

      <v-card-text>
        <v-data-table
          :headers="headers"
          :loading="loading"
          :items="items"
          item-key="id"
          hide-default-footer
          disable-pagination
        >
          <template v-slot:top>
            <v-expansion-panels class="mb-5" :value="1">
              <v-expansion-panel>
                <v-expansion-panel-header> Filtros </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-container fluid>
                    <v-row
                      ><v-col>
                        <v-text-field
                          label="Paciente"
                          placeholder="Pesquise pelo nome/cpf/rg do paciente"
                          v-model="filters.patient"
                        >
                        </v-text-field
                      ></v-col>
                    </v-row>

                    <v-row>
                      <v-col>
                        <v-text-field
                          label="Data inicial"
                          v-model="filters.startDate"
                          type="date"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col>
                        <v-text-field
                          label="Data final"
                          v-model="filters.endDate"
                          type="date"
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>

                    <div class="d-flex justify-end align-center flex-wrap">
                      <v-btn color="success" class="mr-2" @click="loadExams">
                        <v-icon left>mdi-filter</v-icon> Filtrar</v-btn
                      >
                      <v-btn @click="clearFilters"
                        ><v-icon left>mdi-eraser</v-icon> Limpar</v-btn
                      >
                    </div>
                  </v-container>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-btn icon title="Editar exame" @click="openUpdateScreen(item.id)">
              <v-icon> mdi-pencil </v-icon>
            </v-btn>

            <v-btn icon title="Excluir exame" @click="deleteExam(item.id)">
              <v-icon> mdi-delete </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <div class="text-center mt-2">
      <v-pagination
        v-model="filters.currentPage"
        :length="numberOfPages"
        @input="loadExams"
      ></v-pagination>
    </div>
  </div>
</template>

<script>
import errorHandler from "@/helpers/error_handler";

export default {
  components: {},
  data() {
    return {
      resultsPerPage: 50,
      totalRows: 0,
      examModeOptions: [],
      subspecialtyOptions: [],
      search: null,
      loading: false,
      headers: [
        { text: "ID", value: "id" },
        { text: "Data", value: "dateOfRealization" },
        { text: "Paciente", value: "patientName" },
        { text: "Modalidade", value: "examMode" },
        { text: "Status", value: "examStatusName", sortable: false },
        { text: "Ações", value: "actions", sortable: false },
      ],
      items: [],
      filters: {
        patient: null,
        startDate: null,
        endDate: null,
      },
    };
  },
  methods: {
    async loadExams(page) {
      try {
        this.loading = true;

        let currentPage = page ? page : 1;

        const params = {
          pagination: true,
          page: currentPage,
          type: "public",
          ...this.filters,
        };

        let url = `/exams`;

        const response = await this.$axios.get(url, { params });

        this.totalRows = response.data.count ? response.data.count : 0;

        this.setResponseData(response.data.rows);

        this.loading = false;
      } catch (error) {
        this.loading = false;

        const errorHandled = errorHandler.treatError(error);

        await this.$root.$errorDialog(errorHandled, {
          width: "800",
          color: "primary",
        });
      }
    },
    deleteExamFromDataTable(examId) {
      const index = this.items.findIndex((item) => item.id == examId);

      if (index != -1) {
        let copyOfItems = [...this.items];

        copyOfItems.splice(index, 1);

        this.items = copyOfItems;
      }
    },
    async deleteExam(examId) {
      try {
        if (
          !(await this.$root.$confirm(
            "Atenção",
            "Deseja realmente deletar o exame selecionado?",
            {
              color: "primary",
            }
          ))
        )
          return;

        this.loading = true;

        await this.$axios.delete(`/exams/${examId}`);

        this.deleteExamFromDataTable(examId);

        this.loading = false;
      } catch (error) {
        this.loading = false;

        const errorHandled = errorHandler.treatError(error);

        await this.$root.$errorDialog(errorHandled, {
          width: "800",
          color: "primary",
        });
      }
    },
    openInsertScreen() {
      this.$router.push("/exames/cadastrar");
    },
    openUpdateScreen(examId) {
      this.$router.push(`/exames/${examId}/editar`);
    },
    clearFilters() {
      (this.filters = {
        patient: null,
        PartnershipId: null,
        startDate: null,
        endDate: null,
      }),
        this.loadExams();
    },
    setResponseData(data) {
      const copyOfData = [...data];

      this.items = copyOfData.map((item) => {
        item.patientName = item.Patient.Person.fullName;

        if (item.examStatus === "pending") item.examStatusName = "Pendente";
        if (item.examStatus === "requested-resend")
          item.examStatusName = "Reenvio solicitado";
        if (item.examStatus === "completed") item.examStatusName = "Finalizado";

        item.dateOfRealization = this.$moment(item.dateOfRealization)
          .add("3", "hours")
          .format("DD/MM/YYYY [ás] HH:mm");

        return item;
      });
    },
    async loadExamModes() {
      try {
        this.loading = true;

        this.examModeOptions = [];

        const url = `/exam-modes`;

        const res = await this.$axios.get(url);

        this.setExamModesData(res.data);

        this.loading = false;
      } catch (error) {
        this.loading = false;

        const errorHandled = errorHandler.treatError(error);

        await this.$root.$errorDialog(errorHandled, {
          width: "800",
          color: "primary",
        });
      }
    },
    setExamModesData(data) {
      this.examModeOptions = data.map((d) => {
        return {
          value: d.id,
          text: d.name,
        };
      });

      this.loading = false;
    },
  },
  computed: {
    numberOfPages() {
      return Math.ceil(this.totalRows / this.resultsPerPage);
    },
  },
  mounted() {
    this.loadExams();
    this.loadExamModes();
  },
};
</script>

<style></style>
